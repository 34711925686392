<template>
  <div id="app" class="frame" data-server-rendered>
    <header>
      <nav class="navbar navbar-expand-md navbar-dark">
        <div class="container">
          <a class="navbar-brand" href="http://www.surprisewin.cn">
            <img src="@a/logo.png" alt="京禧集团 Logo">
          </a>
          <button class="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse">
            <i class="navbar-toggler-icon"></i>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbar-collapse">
            <ul class="navbar-nav">
              <li class="nav-item" v-for="item in navItems" :key="item.to">
                <router-link class="nav-link" :to="item.to">{{ item.title }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    <router-view/>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col">
            <p>© {{ year }} 广东京禧集团有限公司</p>
            <p>
              <a href="https://www.surprisewin.cn/">www.surprisewin.cn</a>
            </p>
            <p>
              <a href="mailto:jx@surprisewin.cn">jx@surprisewin.cn</a>
            </p>
          </div>
          <div class="col">
            <h5>导航</h5>
            <ul class="list-unstyled">
              <li v-for="item in navItems" :key="item.to">
                <router-link :to="item.to">{{ item.title }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
    name: 'frame-home',
    data() {
        const route = this.$router.options.routes[0];
        let navItems = route.children.map(function(v) {
            return {
                to: '/' + v.path,
                title: v.meta.title || v.name
            };
        });

        return {
            navItems,
            year: new Date().getFullYear()
        };
    }
};
</script>

<style lang="less">
@import '../../style/mixin';

#app {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;

    > .page {
        flex-grow: 1;

        @media (max-width: 767px) {
            .bg-jumbotron {
                .p-y(5rem);
            }
        }
    }

    > header {
        .navbar {
            position: absolute;
            top: 1rem;
            width: 100%;
            z-index: 1000;

            .nav-link {
                .p-x(1rem);
                transition: background-color 0.5s;
                color: white;

                &.router-link-exact-active {
                    background-color: rgba(255, 255, 255, 0.2);
                    border-radius: 0.25rem;
                }
            }
        }
        @media (max-width: 767px) {
            .navbar {
                position: static;
                background-color: var(--gray);
            }
        }
    }

    > footer {
        .p-y(2rem);
        background-color: var(--light);
        h5 {
            font-size: 1rem;
        }
        a {
            color: var(--gray);
        }
    }
}
</style>